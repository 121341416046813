
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import { applyNavbarButtons } from '@/pages-site-v2/helpers/navbar'
@Options({})
export default class Navbar extends Vue {
  mounted(): void {
    applyNavbarButtons()
  }
}
