export function applyNavbarButtons(): void {
  if (window.localStorage.auth_token) {
    const loggedOutButtons = document.querySelectorAll('.button-logged-out')
    const loggedInButtons = document.querySelectorAll('.button-logged-in')
    loggedOutButtons.forEach((object) => {
      ;(object as any).style.display = 'none'
    })
    loggedInButtons.forEach((object) => {
      // Reset existing display style on element
      ;(object as any).style.display = null
    })
  }
}
