import { IS_NOT_PRODUCTION, IS_NOT_PRERENDERING } from '@/init/settings'

// See https://github.com/GoogleChrome/web-vitals
export function initWebVitals(): void {
  const initWebVitals = function (): void {
    const script = document.createElement('script')
    script.src = 'https://unpkg.com/web-vitals@4/dist/web-vitals.iife.js'
    script.onload = function (): void {
      console.log('Web vitals: script loaded', webVitals)
      // When loading `web-vitals` using a classic script, all the public
      // methods can be found on the `webVitals` global namespace.
      webVitals.onCLS(console.log)
      webVitals.onFID(console.log)
      webVitals.onLCP(console.log)
    }
    document.head.appendChild(script)
  }

  if (IS_NOT_PRODUCTION && IS_NOT_PRERENDERING) {
    console.log('Web vitals: init')
    initWebVitals()
  }
}
