import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (!_ctx.withText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("img", {
            src: "/img/logo.svg",
            class: _normalizeClass(_ctx.logoClass),
            alt: "..."
          }, null, 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.withText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("img", {
            src: "/img/logo-text.svg",
            class: _normalizeClass(_ctx.logoClass),
            alt: "..."
          }, null, 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}