/**
 * Get the browser based on the value in `navigator.userAgent`.
 * @returns {"chrome" | "safari" | "firefox" | "edge" | "other"}
 */
export const browserName = ():
  | 'chrome'
  | 'safari'
  | 'firefox'
  | 'edge'
  | 'other' => {
  const userAgent = navigator.userAgent

  if (userAgent.includes('Firefox')) {
    return 'firefox'
  } else if (userAgent.includes('Edg')) {
    return 'edge'
  } else if (userAgent.includes('Chrome')) {
    return 'chrome'
  } else if (userAgent.includes('Safari')) {
    return 'safari'
  } else {
    return 'other'
  }
}
