
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { TestimonialRecord, DATA } from '@/pages-site-v2/helpers/testimonials'

@Options({})
export default class WhyPeopleLove extends Vue {
  @Prop({}) private content!: keyof typeof DATA

  get items(): TestimonialRecord[] {
    return DATA[this.content]
  }

  itemImage(item: TestimonialRecord): string {
    // See https://stackoverflow.com/q/40491506/4612064
    // The 'require' here allows to use dynamic image path
    return require(`@/assets.v2/images/${item.image}`)
  }
}
