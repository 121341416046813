
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Navbar from '@/components/public/Navbar.vue'
import Footer from '@/components/public/Footer.vue'
import SignupWithEmailForm from '@/components/public/SignupWithEmailForm.vue'

@Options({
  components: {
    Navbar,
    SignupWithEmailForm,
    Footer,
  },
})
export default class SiteTerms extends Vue {}
