import {
  createRouter as _createRouter,
  createWebHistory,
  Router,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router'
import routes from './routes'

import { updateMetaTags } from '@/helpers/router'
import { auth } from '@/services/auth'
import { Mixpanel } from '@/analytics/mixpanel'
import { Sentry } from '@/services/sentry'

export function createRouter(): Router {
  const router = _createRouter({
    history: createWebHistory(),
    routes: routes,
  })

  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const pageName = (to.name as string) || ''
      const isSignupPage = pageName === 'signup'
      const isLoginPage = pageName === 'login'

      const publicPages = [
        'signup',
        'login',
        'password_forgot',
        'password_reset',
        'public_book',
        'public_pdf',
        'site_index',
        'site_about',
        'site_pricing',
        'site_summaries',
        'site_terms',
        'site_delete_account',
        'site_video',
        'site_privacy',
        'public_not_found',
        'public_highlight',
        'extension_auth',
      ]

      if (auth.loggedIn()) {
        try {
          // Add token to Sentry.
          Sentry.configureScope((scope) => {
            scope.setUser(auth.getUser() as any)
          })
        } catch (error) {
          // This should not happen, but if it does (see #6372),
          // it breaks the application initialization, so
          // it is safer to have try/catch around this block.
          console.log(error)
        }
      }

      // If we are not logged in and page requires authorization,
      // redirect to signup page
      if (!auth.loggedIn() && publicPages.indexOf(pageName) === -1) {
        next({
          name: 'signup',
          query: { redirect: to.fullPath },
        })
      } else if (auth.loggedIn() && (isSignupPage || isLoginPage)) {
        // If we are logged in and try to visit signup page,
        // redirect to books
        next({
          name: 'books',
        })
      } else {
        next() // Make sure to always call next()!
      }
    },
  )

  router.beforeEach((to, from, next) => {
    updateMetaTags(to)
    next()
  })

  router.afterEach(() => {
    if ((window as any)['__PRERENDER_INJECTED']) {
      // Skip mixpanel page tracking during prerendering.
      return
    }
    setTimeout(() => {
      // Delay a page view call a bit to wait for window.locaion.href to change
      // to the new URL
      // See
      // - https://github.com/vuejs/vue-router/issues/1197
      // - https://github.com/vuejs/vue-router/pull/2292
      // - https://github.com/vuejs/vue-router/issues/2079
      Mixpanel.pageView()
    }, 100)
  })

  return router
}

export const router = createRouter()
