import { APP_TITLE } from '@/init/settings'

export type TagData = {
  [key: string]: string
}

export function setTitle(value: string): void {
  document.title = value
}

export function setAppTitle(value: string): void {
  setTitle(APP_TITLE + ' | ' + value)
}

/*
 * Remove any stale meta tags from the document.
 */
export function removeMetaTags(): void {
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    // @ts-ignore
    (el) => el.parentNode.removeChild(el),
  )
}

/*
 * Add meta tags to the page, tags are specified as an array of
 * objects with name / content keys:
 *
 *   metaTags: [
 *     {
 *       name: 'description',
 *       content:
 *         "Learn what exactly makes Shortform's book summaries the best in the world. Comprehensive, organized, cutting out fluff, and more.",
 *     }
 *   ],
 *
 */
export function addMetaTags(metaTags: TagData[]): void {
  // Turn the meta tag definitions into actual elements in the head.
  return addTags('meta', metaTags)
}

/*
 * Add link tags to the page, tags are specified as an array of
 * objects with name / content keys:
 *
 *   linkTags: [
 *     { rel: 'canonical', href: '/page/url', id: 'canonical' }
 *     { rel: 'icon', href: './path/to/ico.png', sizes: '16x16', type: 'image/png' }
 *   ],
 *
 */
export function addLinkTags(linkTags: TagData[]): void {
  return addTags('link', linkTags)
}

export function addTags(tagName: string, metaTags: TagData[]): void {
  // Turn the meta tag definitions into actual elements in the head.
  metaTags
    .map((tagDef: TagData) => {
      const tag = document.createElement(tagName)
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create.
      tag.setAttribute('data-vue-router-controlled', '')
      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag))
}

/**
 * Return current URL without traling slash.
 *
 * Can be used with meta.addLinkTags to add a rel=canonical link:
 *
 * router.beforeEach((to, from, next) => {
 *   meta.removeMetaTags()
 *   meta.addLinkTags([
 *     {
 *       rel: 'canonical',
 *       href: meta.getCurrentCanonicalUrl(to.fullPath),
 *       id: 'canonical',
 *     }
 *   ])
 * })
 *
 */
export function getCurrentCanonicalUrl(currentPath: string): string {
  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1)
  }

  // AI vs Human Guides Experiment (#13747): Set canonical URL always to human guide
  currentPath = currentPath.replace('-vb13747', '')

  // We always use the production URL as production is the only
  // place where canonical URL matters.
  return 'https://www.shortform.com' + currentPath
}

export function updateSEOFields(
  url: string,
  title: string,
  description: string,
  image: string,
): void {
  // Removing stale meta tags
  removeMetaTags()

  setTitle(title)
  addLinkTags([
    {
      rel: 'canonical',
      href: url,
      id: 'canonical',
    },
  ])
  addMetaTags([
    {
      name: 'description',
      content: description,
    },
    // Open Graph tags for facebook
    { property: 'og:url', content: url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: image,
    },
    // Meta tags for Twitter
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@_shortform' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:image', content: image },
    {
      property: 'twitter:description',
      content: description,
    },
  ])
}

/*
 * Helper to handle meta data updates.
 *
 * Note: the method to update meta tags is from
 * https://alligator.io/vuejs/vue-router-modify-head/
 *
 * Note: the original snippet is more complex and also handles nested
 * routes by fetching meta data from the parent route.
 * We don't need that behavior at the moment, so use a simpler version.
 *
 */
export * as meta from './meta'
