import { Vue } from 'vue-class-component'

// Register the router hooks with their names
export function initClassComponentHooks(): void {
  Vue.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
  ])
}
