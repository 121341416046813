// Register Vue router hooks for class-based components.
// Should be done before importing Vue and other components.
// Should be imported from the external module (doesn't work as inline code).
import { initClassComponentHooks } from '@/init/class-component-hooks'
initClassComponentHooks()

import { createApp, h } from 'vue'

import App from './App.vue'
import { router } from './router'

import '@/assets.v2/css/style.scss'
// eslint-disable-next-line import/no-unassigned-import

// Common (public / vue app) initialization code
import { initPageSiteV2 } from './init'
initPageSiteV2()
import { initWebVitals } from '@/init/webVitals'
initWebVitals()

// Import scripts
// eslint-disable-next-line import/no-unassigned-import
import '@/assets.v2/js/pagesSite.js'

// Global UI components.
import Logo from '@/components/ui/Logo.vue'

// @ts-ignore
const app = createApp({
  router,
  render: (): any => h(App),
})

app.use(router)
app.component('Logo', Logo)
app.mount('#appRoot')
