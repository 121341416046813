
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Navbar from '@/components/public/Navbar.vue'
import Footer from '@/components/public/Footer.vue'
import WhyTheBest from '@/components/public/WhyTheBest.vue'

@Options({
  components: {
    Navbar,
    WhyTheBest,
    Footer,
  },
})
export default class Public404Page extends Vue {}
