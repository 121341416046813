import { Ref } from 'vue'
import {
  isNavigationFailure,
  RouteLocationNormalized,
  Router,
} from 'vue-router'

import { meta, TagData } from '@/services/meta'
import { capitalizeTagFirstLetter, urlSlugToTitle } from '@/helpers/text'

export function router(): Router {
  // @ts-ignore
  return window._vueRouter
}

export function currentRoute(): Ref<RouteLocationNormalized> {
  // @ts-ignore
  return window._vueRouter.currentRoute
}

export async function navigate(
  location: any,
  ignoreNavigationError: boolean = true,
): Promise<any> {
  // @ts-ignore
  const router = window._vueRouter

  try {
    const result = await router.push(location)
    if (isNavigationFailure(result)) {
      return result
    }
    return router.currentRoute.value
  } catch (err) {
    if (ignoreNavigationError && isNavigationFailure(err)) {
      // Resolve and return err, so there is no unhandled promise
      // rejecton exception.
      // The error can happen when we navigate to the same route,
      // abort the navigation in the navigation hook or redirect
      // somewhere else during the navigation.
      // Note: if we decide to do more specific check, we can use
      // the `if (!isNavigationFailure(err, NavigationFailureType.duplicated)`
      // form (check the `NavigationFailureType` in vue router code,
      // `src/util/errors.js`.
      return err
    }
    throw err
  }
}

export function pageTitle(to: RouteLocationNormalized): string {
  // Note: some of metadata handling might also be done in components,
  // see BookSummaryPage.updateMeta for example.
  if (to.meta && to.meta.title) {
    return to.meta.title as string
  }

  // Convert dynamic URL parameters like {name: 'popular'}
  // to the title string.
  // This is used as a fallback: if we do not have `title` specified and
  // the url has dynamic parameters, we will generate the title from them.
  if (to.params) {
    let dynamicLabel = ''
    for (const name in to.params) {
      const value = to.params[name] as string
      if (value) {
        if (name === 'category') {
          dynamicLabel = dynamicLabel + capitalizeTagFirstLetter(value)
        } else {
          dynamicLabel = dynamicLabel + urlSlugToTitle(value)
        }
      }
    }
    return dynamicLabel
  }

  return ''
}

/**
 * Update meta tags for the given route.
 *
 * @param to - route to update meta tags for
 * @param addAppTitle - add the app title to the page title
 *
 * If `addAppTitle` is true, the app title ("Shortform | ") will be added
 * to the page title, otherwise the page title will be set as is.
 *
 */
export function updateMetaTags(
  to: RouteLocationNormalized,
  addAppTitle: boolean = false,
): void {
  // Note: some of metadata handling might also be done in components,
  // see BookSummaryPage.updateMeta for example.
  if (addAppTitle) {
    meta.setAppTitle(pageTitle(to))
  } else {
    meta.setTitle(pageTitle(to))
  }

  // Snippet source: https://alligator.io/vuejs/vue-router-modify-head/
  // Note: the original snippet is more complex and also handles nested
  // routes by fetching meta data from the parent route.
  // We don't need that behavior at the moment, so use a simpler version.
  //
  // Remove any stale meta tags from the document.
  meta.removeMetaTags()

  meta.addLinkTags([
    {
      rel: 'canonical',
      href: meta.getCurrentCanonicalUrl(to.fullPath),
      id: 'canonical',
    },
  ])

  if (to.meta) {
    // Skip rendering meta tags if there are none.
    if (!to.meta.metaTags) {
      return
    }
    // Turn the meta tag definitions into actual elements in the head.
    meta.addMetaTags(to.meta.metaTags as TagData[])
  }
}
