import { initMixpanel } from '@/init/mixpanel'
import { sentryInit } from '@/init/sentry-init'

const { testimonials } = require('@/assets.v2/js/pagesSite.js')
const { header } = require('@/assets.v2/js/header.js')

import { Mixpanel } from '@/analytics/mixpanel'
import { initAB } from '@/services/ab'
import { assertNotNull } from '@/helpers/typing'
import { setLocation } from '@/helpers/routes'
import { applyNavbarButtons } from '@/pages-site-v2/helpers/navbar'

export function initPageSiteV2(): void {
  initMixpanel()
  sentryInit()

  document.addEventListener('DOMContentLoaded', function () {
    header()
    testimonials()
  })
}

export function onPageLoad(): void {
  applyNavbarButtons()

  initAB()

  setupEmailForm()

  Mixpanel.pageView()
}

export function setupEmailForm(): void {
  const form = document.querySelector('.get-free-summary__form')
  if (form) {
    form.addEventListener('submit', handleEmailSubmit)
  }
}

// Handle signup with email form submit
function handleEmailSubmit(event: Event): void {
  event.preventDefault()

  const email = assertNotNull(
    document.querySelector('.get-free-summary__input') as HTMLInputElement,
  )
  const emailValue = encodeURIComponent(email.value)
  setLocation(`/app/signup?email=${emailValue}`, () => {})
}
