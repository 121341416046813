
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Navbar from '@/components/public/Navbar.vue'
import WhyPeopleLove from '@/components/public/WhyPeopleLove.vue'
import Footer from '@/components/public/Footer.vue'

@Options({
  components: {
    Navbar,
    WhyPeopleLove,
    Footer,
  },
})
export default class SiteAbout extends Vue {}
